import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;

import './bootstrap';

import Alpine from 'alpinejs';
window.Alpine = Alpine;
Alpine.start();

window.getApiToken = () => {
    const apiTokenMeta = document.querySelector('meta[name="_lt_api_token"]');
    const apiToken = apiTokenMeta ? apiTokenMeta.getAttribute('content') : '';
    return apiToken;
}

function logErrorToServer(error, url) {
    const apiTokenMeta = document.querySelector('meta[name="_lt_api_token"]');
    const apiToken = apiTokenMeta ? apiTokenMeta.getAttribute('content') : '';
    fetch('/api/log-error', { // or '/log-error' if you defined the route in web.php
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${apiToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            url: url,
            message: error.message,
            stack: error.stack // Optional, but useful for debugging
        })
    }).then(response => response.json())
        .then(data => console.log('Error logged:', data))
        .catch(err => console.error('Failed to log error:', err));
}

window.logErrorToServer = logErrorToServer;

// enable debug mode
window.APP_DEBUG = true;
